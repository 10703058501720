<template>
  <div @click="$emit('input', !value)">
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon :color="value ? 'success' : 'error'" v-bind="attrs" v-on="on">{{ value ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
      </template>
      <span>{{ tooltip }}</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: 'EyeBox',
  props: {
    value: Boolean,
    tooltip: String
  }
}
</script>

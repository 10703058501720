<template>
  <div class="tel-field">
    <v-input :error="valid === false" :error-messages="valid === false ? ['Invalid'] : null">
      <v-icon slot="prepend">{{ icon }}</v-icon>
      <v-label :value="labelValue" absolute left="56">{{ label }}</v-label>
      <vue-tel-input v-model="val" :mode="mode" :disabled="readonly" @focus="focused = true" @blur="focused = false" @validate="validate"/>
    </v-input>
  </div>
</template>

<script>
const ICONS = { telm: 'cellphone', telp: 'phone', telw: 'deskphone' }

export default {
  name: 'TelField',
  props: {
    value: String,
    type: { type: String, default: 'telm' }, // telm|telp|telw
    readonly: Boolean
  },
  data () {
    return {
      val: null,
      mode: 'auto',
      valid: null,
      focused: false
    }
  },
  computed: {
    label () {
      return this.$t(this.type)
    },
    icon () {
      return 'mdi-' + ICONS[this.type]
    },
    labelValue () {
      return !!this.focused || !!this.val
    }
  },
  methods: {
    validate ($event) {
      this.valid = $event.valid
      if ($event.valid) {
        this.val = $event.formatted
        this.$emit('input', $event.number)
      } else {
        this.$emit('input', '')
      }
    }
  },
  created () {
    if (!this.value || this.value[0] === '0' || this.value.substr(0, 3) === '+41') {
      this.mode = 'national'
    }
    this.val = this.value
  }
}
</script>

<style scoped>
.tel-field {
  padding-top: 12px;
  margin-top: 4px;
}
.tel-field .v-label {
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  top: 8px;
  left: 56px;
  white-space: nowrap;
  pointer-events: none;
}
.tel-field .v-label--active {
  max-width: 133%;
  transform: translate(-62px, -24px) scale(0.75);
  pointer-events: auto;
}
.tel-field .vue-tel-input {
  width: 100%;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.42);
}
.tel-field .vue-tel-input:focus-within {
  box-shadow: none;
}
</style>
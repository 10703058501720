<template>
  <div>
    <div v-if="editUser.uid" class="text-center mt-5">
      <div v-if="!showEditImg">
        <user-avatar :user="editUser" large @click="editImg = editUser.img; showEditImg = true"/>
        <div v-if="!editUser.img" class="mt-2">
          <v-btn text small @click="showEditImg = true">Profilbild wählen</v-btn>
        </div>
      </div>
      <div v-else class="px-15">
        <img-upload v-model="editImg" closable @input="onImg" @close="showEditImg = false"/>
      </div>
    </div>

    <div class="text-center text-overline mt-8 mb-3">Person</div>

    <v-row no-gutters align="center">
      <v-col>
        <v-text-field v-if="$cfg.signUp && $cfg.signUp.nameEditable" v-model="editUser.name" label="Name" autocomplete="name" @input="input">
          <template v-slot:append>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">
                  mdi-help-circle-outline
                </v-icon>
              </template>
              Der Name der überall angezeigt wird, kann auch ein Übername oder eine Abkürzung sein. Vorschlag: {{ $cfg.nameHint }}
            </v-tooltip>
          </template>
        </v-text-field>
        <div class="d-flex">
          <v-text-field v-model="editUser.forename" label="Vorname" autocomplete="given-name" class="mr-3" style="flex: 1" @input="input"/>
          <v-text-field v-model="editUser.lastname" label="Nachname" autocomplete="family-name" style="flex: 1.5" @input="input"/>
        </div>
      </v-col>
      <v-col cols="2" class="text-right">
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col>
        <date-field v-model="editUser.birthday" label="Geburtstag" start-with-year @input="input"/>
      </v-col>
      <v-col cols="2" class="text-right">
        <eye-box v-model="editUser.public.birthday" tooltip="für alle sichtbar? grün=ja, rot=nein" @input="input"/>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col>
        <v-radio-group
          v-model="editUser.sex"
          label="Geschlecht"
          dense
          row
          class="radios"
          @input="input"
        >
          <v-radio
            label="Männlich"
            value="m"
          ></v-radio>
          <v-radio
            label="Weiblich"
            value="f"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="2" class="text-right">
      </v-col>
    </v-row>

    <div class="text-center text-overline mt-8 mb-3">Adresse</div>

    <v-row no-gutters align="center">
      <v-col>
        <address-fields v-model="editUser.address" @input="input"/>
      </v-col>
      <v-col cols="2" class="text-right">
        <eye-box v-model="editUser.public.address" tooltip="für alle sichtbar? grün=ja, rot=nein" @input="input"/>
      </v-col>
    </v-row>

    <div class="text-center text-overline mt-8 mb-3">Login &amp; Kontakt</div>

    <v-row no-gutters align="center">
      <v-col>
        <v-text-field v-model="editUser.email" type="email" label="E-Mail" prepend-icon="mdi-at" :readonly="!admin" :autocomplete="admin ? 'off' : ''"/>
      </v-col>
      <v-col cols="2" class="text-right">
        <eye-box v-model="editUser.public.email" tooltip="für alle sichtbar? grün=ja, rot=nein" @input="input"/>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col>
        <tel-field v-model="editUser.telm" type="telm" :readonly="!admin" @input="input"/>
        <!-- <vue-tel-input v-model="editUser.telp" @validate="onTel"/> -->
      </v-col>
      <v-col cols="2" class="text-right">
        <eye-box v-model="editUser.public.telm" tooltip="für alle sichtbar? grün=ja, rot=nein" @input="input"/>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col>
        <tel-field v-model="editUser.telp" type="telp" @input="input"/>
      </v-col>
      <v-col cols="2" class="text-right">
        <eye-box v-model="editUser.public.telp" tooltip="für alle sichtbar? grün=ja, rot=nein" @input="input"/>
      </v-col>
    </v-row>
    <v-row no-gutters align="center">
      <v-col>
        <tel-field v-model="editUser.telw" type="telw" @input="input"/>
      </v-col>
      <v-col cols="2" class="text-right">
        <eye-box v-model="editUser.public.telw" tooltip="für alle sichtbar? grün=ja, rot=nein" @input="input"/>
      </v-col>
    </v-row>

    <slot>
      <div class="text-center my-5">
        <div><v-btn color="primary" :disabled="!dirty || !valid" :loading="saving" class="mb-5" @click="save">Speichern</v-btn></div>
        <div><v-btn text small :disabled="dirty || saving" @click="editPassword = true">Passwort ändern</v-btn></div>
      </div>
    </slot>

    <v-dialog
      v-if="editPassword"
      :value="true"
      persistent
      width="400"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Password ändern</v-toolbar-title>
          <v-spacer/>
          <v-btn icon dark @click="editPassword = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="py-5">
          <v-text-field v-model="password" label="Passwort" type="password" hint="Mind. 6 Zeichen"/>
          <v-text-field v-model="password_repeat" label="Passwort wiederholen" type="password"/>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <div><v-btn color="primary" :disabled="!isPasswordValid" :loading="saving" @click="savePassword">Speichern</v-btn></div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UserAvatar from '@/components/base/UserAvatar'
import ImgUpload from '@/components/base/ImgUpload'
import AddressFields from '@/components/base/AddressFields'
import DateField from '@/components/base/DateField'
import TelField from '@/components/base/TelField'
import EyeBox from '@/components/base/EyeBox'

export default {
  name: 'UserProfileEdit',
  components: { UserAvatar, ImgUpload, AddressFields, DateField, TelField, EyeBox },
  props: {
    user: Object,
    name: String,
    admin: Boolean
  },
  model: {
    prop: 'user'
  },
  data () {
    return {
      editUser: {},
      showEditImg: false,
      editImg: null,
      editPassword: null,
      password: null,
      password_repeat: null,
      saving: false
    }
  },
  computed: {
    dirty () {
      return this.user && (
        this.user.img?.src !== this.editUser.img?.src ||
        this.user.name !== this.editUser.name ||
        this.user.forename !== this.editUser.forename ||
        this.user.lastname !== this.editUser.lastname ||
        this.user.birthday !== this.editUser.birthday ||
        this.user.sex !== this.editUser.sex ||
        this.user.address?.plz !== this.editUser.address?.plz ||
        this.user.address?.city !== this.editUser.address?.city ||
        this.user.address?.street !== this.editUser.address?.street ||
        this.user.telm !== this.editUser.telm ||
        this.user.telp !== this.editUser.telp ||
        this.user.telw !== this.editUser.telw ||
        this.user.public?.birthday !== this.editUser.public?.birthday ||
        this.user.public?.address !== this.editUser.public?.address ||
        this.user.public?.email !== this.editUser.public?.email ||
        this.user.public?.telm !== this.editUser.public?.telm ||
        this.user.public?.telp !== this.editUser.public?.telp ||
        this.user.public?.telw !== this.editUser.public?.telw
      )
    },
    valid () {
      return this.editUser.forename && this.editUser.lastname
    },
    isPasswordValid () {
      return this.password && this.password.length > 5 && this.password === this.password_repeat
    }
  },
  methods: {
    async save () {
      this.saving = true
      const name = this.$cfg.signUp?.nameEditable
        ? this.editUser.name
        : (this.editUser.forename + ' ' + this.editUser.lastname).trim()
      const update = {
        img: this.editUser.img || {},
        name,
        forename: this.editUser.forename,
        lastname: this.editUser.lastname,
        birthday: this.editUser.birthday || '',
        sex: this.editUser.sex || '',
        address: this.editUser.address || {},
        telp: this.editUser.telp || '',
        telw: this.editUser.telw || '',
        public: {
          birthday: this.editUser.public?.birthday || false,
          address: this.editUser.public?.address || false,
          email: this.editUser.public?.email || false,
          telm: this.editUser.public?.telm || false,
          telp: this.editUser.public?.telp || false,
          telw: this.editUser.public?.telw || false
        }
      }
      console.log(update, this.user.uid)
      // this should not even happen as admin calls saveUser instead..
      // if (this.admin) { // only admin can change email/phone (auth)
      //   update.email = this.editUser.email || ''
      //   update.telm = this.editUser.telm || ''
      // }
      await this.$fb.db.doc('users/' + this.user.uid).update(update)
      this.$root.reloadData()
      this.saving = false
      this.$emit('close')
    },
    async onImg () {
      if (!this.editImg?.src || this.editImg?.src !== this.user.editImg?.src) {
        const img = this.editImg ? { ...this.editImg } : {}
        await this.$fb.db.doc('users/' + this.user.uid).update({
          img
        })
        this.$root.reloadData()
        this.editUser.img = img
        this.showEditImg = false
        this.input()
      }
    },
    input () {
      this.$emit('input', this.editUser)
    },
    async savePassword () {
      this.saving = true
      const saveUserPasswordFunc = this.$fb.fn.httpsCallable('saveUserPassword')
      const response = await saveUserPasswordFunc({
        password: this.password
      })
      if (response.data.ok) {
        this.editPassword = false
        this.saving = false
        this.$root.snack = {
          text: 'Passwort geändert'
        }
      }
    },
  },
  watch: {
    user: {
      handler (user) {
        if (user && (Object.keys(this.editUser).length === 0 || !this.editUser.uid && user.uid)) {
          this.editUser = {
            ...user,
            public: {
              ...(this.$cfg.signUp?.publicDefaults || {}),
              ...user.public
            }
          }
          if (!this.editUser.name && this.name) {
            this.editUser.name = this.name
          }
        }
      },
      immediate: true
    },
    valid: {
      handler (valid) {
        this.$emit('valid', valid)
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
@media screen and (max-width: 600px) {
  .radios >>> .v-radio {
    width: 100%;
  }
}
</style>